import CloseIcon from '@cointracker/assets/general/close.svg?react';
import InfoIcon from '@cointracker/assets/general/info-icon.svg?react';
import classNames from 'classnames';
import { useCallback, useState, type ReactNode } from 'react';
import { themeVars } from '../../../theme';
import { Box } from '../../layout/Box';
import { Button } from '../Button';
import {
  banner,
  centeredText,
  closeError,
  flex1,
  marginRight,
  spanLineHeight1,
} from './Banner.css';

export interface BannerProps {
  children?: ReactNode;
  href?: string;
  type?: 'success' | 'warning' | 'error';
  closable?: boolean;
  withPrefixIcon?: boolean;
  className?: string;
  onBannerClose?: () => void;
  rounded?: boolean;
}

export const Banner = ({
  children,
  href,
  type,
  className,
  closable = true,
  withPrefixIcon = true,
  rounded = false,
  onBannerClose,
}: BannerProps) => {
  const [open, setOpen] = useState(true);
  const onClose = useCallback(() => {
    setOpen(false);
    if (onBannerClose) {
      onBannerClose();
    }
  }, [onBannerClose]);
  if (!open) return null;
  return (
    <div
      className={classNames(
        banner({
          rounded: rounded,
          type: type,
        }),
        className,
      )}
    >
      {closable && <div className={flex1} />}
      <Box {...{ href }} as={href ? 'a' : 'span'} className={centeredText}>
        <span className={classNames(spanLineHeight1, marginRight)}>
          {withPrefixIcon && (
            <InfoIcon color={themeVars.grey.white} height={16} width={16} />
          )}
        </span>
        <span className={spanLineHeight1}>{children}</span>
      </Box>
      {closable && (
        <div className={flex1}>
          <Button
            className={closeError}
            variant="plain"
            onClick={onClose}
            aria-label="close-banner-button"
          >
            <CloseIcon color={themeVars.grey.white} width={12} height={12} />
          </Button>
        </div>
      )}
    </div>
  );
};

export default Banner;
