import { Banner, useSearchParams, type BannerProps } from '@cointracker/ui';
import React, { useCallback, useEffect, useState } from 'react';

interface Messages {
  [key: string]: {
    closable: boolean;
  };
}

enum Category {
  Error = 'Error',
  Warning = 'Warning',
  Success = 'Success',
}

type BannerMessages = {
  [key in Category]?: Messages;
};
export const GlobalBanners = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [bannerMessages, setBannerMessages] = useState<BannerMessages>({});

  const closeBanner = useCallback((category: Category, message: string) => {
    setBannerMessages((bannerMessages) => {
      delete bannerMessages[category]?.[message];
      return { ...bannerMessages };
    });
  }, []);

  useEffect(() => {
    const handleMessage = (paramName: string, category: Category) => {
      const message = searchParams.get(paramName);
      if (message) {
        searchParams.delete(paramName);
        setSearchParams(searchParams);
        const decodedMessage = decodeURIComponent(message);
        setBannerMessages((oldBannerMessages) => ({
          ...oldBannerMessages,
          [category]: {
            ...oldBannerMessages[category],
            [decodedMessage]: { closable: true },
          },
        }));
      }
    };

    handleMessage('errorMessage', Category.Error);
    handleMessage('successMessage', Category.Success);
    handleMessage('warningMessage', Category.Warning);
  }, [searchParams, setSearchParams]);

  /**
   * Create a map of categories and their messages in a dictionary
   * There are some duplicate messages so remove those implicity by having a map of messages
   *
   * Ex.
   * {
   *    'warning': {
   *      'test warning': {closable: true },
   *      'hello warning': {closable: true }
   *     },
   *     'error': {
   *        'test error': {closable: true }
   *     }
   * }
   */
  useEffect(() => {
    // Add maintenance message if needed
    const warningMessages: Messages = {};
    const errorMessages: Messages = {};

    setBannerMessages((oldBannerMessages) => {
      const bannerMessages: BannerMessages = { ...oldBannerMessages };
      bannerMessages[Category.Error] = {
        ...bannerMessages[Category.Error],
        ...errorMessages,
      };
      bannerMessages[Category.Warning] = {
        ...bannerMessages[Category.Warning],
        ...warningMessages,
      };
      return bannerMessages;
    });
  }, []);

  // Convert map of messages to list of banners to display
  const Banners: JSX.Element[] = [];
  for (const c in bannerMessages) {
    const category = c as Category;
    for (const message in bannerMessages[category]) {
      const messageProps = bannerMessages[category]?.[message];
      Banners.push(
        <Banner
          key={`${category}-${message}`}
          closable={messageProps?.closable}
          type={category.toLowerCase() as BannerProps['type']}
          onBannerClose={() => closeBanner(category, message)}
        >
          {message}
        </Banner>,
      );
    }
  }
  return <>{Banners}</>;
};
